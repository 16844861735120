.ltrEffect {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size:0 2px;
  transition:background-size 0.3s;
  padding-bottom:5px;
  cursor:pointer;
}

.ltrEffect:hover {
  background-size:100% 2px;
  background-position: left bottom;
}